<template>
    <div class="text-center">
    <v-snackbar
      color="success"
      class="pt-12 alert-radius"
      text
      top
      centered
      min-width="315"
      v-model="show"
      :timeout="timeout"
      >{{textMessage}}
    </v-snackbar>
  </div>
</template>
<script>
import Vue from 'vue'
import { mapState } from 'vuex'
import {alertMessage} from '@/assets/messageAlert'
export default Vue.extend({
    data: () => ({
      timeout: 2000,
      text: ''
    }),
    computed: {
      ...mapState({
        type: state => state.alert.success.type,
        show: state => state.alert.success.show
      }),
      textMessage () {
        const textMessage = alertMessage.success[this.type]
        return textMessage
      }
    }
})
</script>